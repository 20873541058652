$additional: #b2bbf8;

html,
body {
  overflow-x: unset;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-shadow {
  filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.9));
}

.nav-glow {
  filter: drop-shadow(5px 5px 15px rgba(170, 170, 170, 0.4));
}

.navbar a {
  color: black;
}

.navbar a:hover {
  text-decoration: none;
}

.dropdown-menu {
  background-color: #ffdead;
}

.corner-cut {
  --notchSize: 15px;

  clip-path: polygon(
    0% var(--notchSize),
    var(--notchSize) 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100% - var(--notchSize)),
    calc(100% - var(--notchSize)) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
}

.corner-cut-bl {
  --notchSize: 20px;

  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100%) 0%,
    100% 0px,
    100% calc(100% /* - var(--notchSize)*/),
    calc(100% /* - var(--notchSize)*/) 100%,
    var(--notchSize) 100%,
    0% calc(100% - var(--notchSize))
  );
}

.corner-cut-tr {
  --notchSize: 140px;

  clip-path: polygon(
    0% 0px,
    0px 0%,
    calc(100% - var(--notchSize)) 0%,
    100% var(--notchSize),
    100% calc(100%),
    calc(100%) 100%,
    0px 100%,
    0% calc(100%)
  );
}

#basic-nav-dropdown:hover + .navigation {
  min-height: 200px;
}

.btn[type='submit'] {
  --notchSize: 8px;
}

.btn {
  text-transform: uppercase;
}

.tag-wrap {
  filter: drop-shadow(5px 5px 15px rgba(170, 170, 170, 0.4));
}

.tag-wrap:hover {
  filter: drop-shadow(15px 15px 15px rgba(0, 0, 0, 0.9));
}
